import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { JhiEventManager } from 'ng-jhipster';

import { IProfileRx } from 'app/shared/model/profile-rx.model';
import { ProfileRxService } from '../profile-rx.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'jhi-profile-rx-delete-dialog',
  templateUrl: './profile-rx-delete-dialog.component.html'
})
export class ProfileRxDeleteDialogComponent implements OnDestroy {
  profile: IProfileRx;
  private profileRxDeleteDialogComponents$: Subject<void> = new Subject<void>();
  constructor(protected profileService: ProfileRxService, public activeModal: NgbActiveModal, protected eventManager: JhiEventManager) {}

  clear() {
    this.activeModal.dismiss('cancel');
  }

  confirmDelete(id: number) {
    this.profileService
      .delete(id)
      .pipe(takeUntil(this.profileRxDeleteDialogComponents$))
      .subscribe(response => {
        this.eventManager.broadcast({
          name: 'profileListModification',
          content: 'Deleted an profile'
        });
        this.activeModal.dismiss(true);
      });
  }
  public ngOnDestroy(): void {
    this.profileRxDeleteDialogComponents$.next();
    this.profileRxDeleteDialogComponents$.unsubscribe();
  }
}

@Component({
  selector: 'jhi-profile-rx-delete-popup',
  template: ''
})
export class ProfileRxDeletePopupComponent implements OnInit, OnDestroy {
  protected ngbModalRef: NgbModalRef;
  private profileRxDeletePopupComponent$: Subject<void> = new Subject<void>();

  constructor(protected activatedRoute: ActivatedRoute, protected router: Router, protected modalService: NgbModal) {}

  ngOnInit() {
    this.activatedRoute.data.pipe(takeUntil(this.profileRxDeletePopupComponent$)).subscribe(({ profile }) => {
      setTimeout(() => {
        this.ngbModalRef = this.modalService.open(ProfileRxDeleteDialogComponent as Component, { size: 'lg', backdrop: 'static' });
        this.ngbModalRef.componentInstance.profile = profile;
        this.ngbModalRef.result.then(
          result => {
            this.router.navigate(['/profile-rx', { outlets: { popup: null } }]);
            this.ngbModalRef = null;
          },
          reason => {
            this.router.navigate(['/profile-rx', { outlets: { popup: null } }]);
            this.ngbModalRef = null;
          }
        );
      }, 0);
    });
  }

  ngOnDestroy() {
    this.ngbModalRef = null;
    this.profileRxDeletePopupComponent$.next();
    this.profileRxDeletePopupComponent$.unsubscribe();
  }
}
