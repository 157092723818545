import { Injectable } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AddressValidationDialogComponent } from './address-validation-dialog.component';

@Injectable()
export class AddressValidationDialogService {
  constructor(private modalService: NgbModal) {}

  public confirm(
    title: string,
    uspsAddressLine: string,
    userAddressLine: string,
    uspsCity: string,
    userCity: string,
    uspsState: string,
    userState: string,
    uspsZip: string,
    userZip: string,
    dialogSize: 'sm' | 'lg' = 'sm',
    hideButtons?: boolean,
    timeoutOnShow?: boolean
  ): Promise<string> {
    const modalRef = this.modalService.open(AddressValidationDialogComponent, { size: dialogSize, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.uspsAddressLine = uspsAddressLine;
    modalRef.componentInstance.userAddressLine = userAddressLine;
    modalRef.componentInstance.uspsCity = uspsCity;
    modalRef.componentInstance.userCity = userCity;
    modalRef.componentInstance.uspsState = uspsState;
    modalRef.componentInstance.userState = userState;
    modalRef.componentInstance.uspsZip = uspsZip;
    modalRef.componentInstance.userZip = userZip;
    modalRef.componentInstance.hideButtons = hideButtons;
    modalRef.componentInstance.timeoutOnShow = timeoutOnShow;

    return modalRef.result;
  }
}
