import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { isIEBrowser } from 'app/app.constants';

function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

function isNumber(value: any): boolean {
  return !isNaN(toInteger(value));
}

function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  format(date: NgbDateStruct): string {
    if (isIEBrowser) {
      return date
        ? `${isNumber(date.month) ? padNumber(date.month) : ''}/${isNumber(date.day) ? padNumber(date.day) : ''}/${date.year}`
        : '';
    } else {
      return date
        ? `${date.year}-${isNumber(date.month) ? padNumber(date.month) : ''}-${isNumber(date.day) ? padNumber(date.day) : ''}`
        : '';
    }
  }

  parse(value: string): NgbDateStruct {
    return null;
  }
}
