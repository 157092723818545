import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import './vendor';
import { AuthInterceptor } from './blocks/interceptor/auth.interceptor';
import { XsrfInterceptor } from './xsrf-interceptor';
import { AuthExpiredInterceptor } from './blocks/interceptor/auth-expired.interceptor';
import { ErrorHandlerInterceptor } from './blocks/interceptor/errorhandler.interceptor';
import { NotificationInterceptor } from './blocks/interceptor/notification.interceptor';
import { BjcrxSharedModule } from 'app/shared/shared.module';
import { BjcrxCoreModule } from 'app/core/core.module';
import { BjcrxAppRoutingModule } from './app-routing.module';
import { BjcrxHomeModule } from './home/home.module';
import { BjcrxEntityModule } from './entities/entity.module';
// jhipster-needle-angular-add-module-import JHipster will add new module here
import { JhiMainComponent } from './layouts/main/main.component';
import { NavbarComponent } from './layouts/navbar/navbar.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { PageRibbonComponent } from './layouts/profiles/page-ribbon.component';
import { ActiveMenuDirective } from './layouts/navbar/active-menu.directive';
import { ErrorComponent } from './layouts/error/error.component';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { environments } from './app.constants';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  ProfileRxDeleteDialogComponent,
  ProfileRxDeletePopupComponent
} from './entities/profile-rx/profile-rx-delete-dialog/profile-rx-delete-dialog.component';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { VersionCheckService } from './version-check.service';
import { GlobalErrorHandler } from 'app/shared/error-handler';
import { ToastrModule, ToastrService } from 'ngx-toastr';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    BjcrxSharedModule,
    BjcrxCoreModule,
    BjcrxHomeModule,
    ToastrModule.forRoot({
      closeButton: false,
      positionClass: 'toast-bottom-full-width',
      disableTimeOut: true,
      tapToDismiss: true,
      preventDuplicates: true
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDvmr2KzTvl1WDSOeLm0ZB8QcR-p83K7EY'
    }),
    AgmDirectionModule,
    // jhipster-needle-angular-add-module JHipster will add new module here
    BjcrxEntityModule,
    BjcrxAppRoutingModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environments.firebase),
    FontAwesomeModule,
    NgbModule
  ],
  declarations: [
    JhiMainComponent,
    ProfileRxDeleteDialogComponent,
    ProfileRxDeletePopupComponent,
    NavbarComponent,
    ErrorComponent,
    PageRibbonComponent,
    ActiveMenuDirective,
    FooterComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthExpiredInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotificationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XsrfInterceptor,
      multi: true
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    VersionCheckService,
    ToastrService
  ],
  entryComponents: [ProfileRxDeleteDialogComponent, ProfileRxDeletePopupComponent],
  exports: [ToastrModule],
  bootstrap: [JhiMainComponent]
})
export class BjcrxAppModule {}
