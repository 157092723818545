import { Injectable } from '@angular/core';
import { LoginService } from 'app/account/login/login.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { SystemConfigRxService } from 'app/entities/system-config-rx/system-config-rx.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';

@Injectable()
export class IdleService {
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  tokenVal = 900;
  constructor(
    private idle: Idle,
    private keepalive: Keepalive,
    private loginService: LoginService,
    private systemconfigrxservice: SystemConfigRxService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    this.init();
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  init() {
    // sets an idle timeout of 15 mins.
    const systTokenVal = this.systemconfigrxservice.getConfigValueFromLocalStorage('JwtExpryTm');
    if (systTokenVal) {
      this.tokenVal = parseInt(systTokenVal, 10) * 60;
    }
    this.idle.setIdle(this.tokenVal);
    // sets a timeout period of 15 mins. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(5);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => (this.idleState = 'No longer idle.'));
    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.confirmationDialogService
        .confirm('Session Expired', 'Your session has expired. Please login again to continue.', 'DELETE', 'CANCEL', 'lg', true, true)
        .then(confirmed => {
          this.loginService.logoutRedirect();
        });
    });
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
    });
    this.idle.onTimeoutWarning.subscribe(countdown => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));
  }

  checkUserIdle() {
    this.idle.stop();
    this.reset();
  }

  stopWatching() {
    this.idle.stop();
  }
}
