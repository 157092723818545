import { HttpParams } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';

export const createRequestOption = (req?: any): HttpParams => {
  let options: HttpParams = new HttpParams();
  if (req) {
    Object.keys(req).forEach(key => {
      if (key !== 'sort') {
        options = options.set(key, req[key]);
      }
    });
    if (req.sort) {
      req.sort.forEach(val => {
        options = options.append('sort', val);
      });
    }
  }
  return options;
};

export function DateValidator(format = 'MM/DD/YYYY'): any {
  return (control: FormControl): { [key: string]: any } => {
    if (control.value) {
      const val = moment(control.value);

      if (!val.isValid()) {
        return { invalidDate: true };
      }
    }

    return null;
  };
}

export function CheckFutureDate(): any {
  return (control: FormControl): { [key: string]: any } => {
    if (control.value) {
      const val = moment(control.value).isAfter(new Date());

      if (val) {
        return { futuredate: true };
      }
    }

    return null;
  };
}

export function FormatDate(date, format = 'YYYY-MM-DD'): string {
  return moment(date).format(format);
}

export function FormatPhoneNumber(phoneNumber): string {
  if (phoneNumber) {
    phoneNumber = phoneNumber.replace(/[^\d]/g, '');
    return '(' + phoneNumber.substr(0, 3) + ') ' + phoneNumber.substr(3, 3) + '-' + phoneNumber.substr(6, 4);
  }
  return null;
}
