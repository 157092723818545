import { Component, OnInit, HostListener, ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { JhiLanguageService } from 'ng-jhipster';
import { SessionStorageService } from 'ngx-webstorage';

import { VERSION } from 'app/app.constants';
import { JhiLanguageHelper } from 'app/core/language/language.helper';
import { AccountService } from 'app/core/auth/account.service';
import { LoginService } from 'app/account/login/login.service';
import { ProfileService } from 'app/layouts/profiles/profile.service';
import { SystemConfigRxService } from 'app/entities/system-config-rx/system-config-rx.service';
import { PrescriptionRxService } from 'app/entities/prescription-rx/prescription-rx.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { Location } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { IdleService } from 'app/core/auth/idle.service';

@Component({
  selector: 'jhi-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['navbar.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Output() hideSidebar: EventEmitter<any> = new EventEmitter<any>();
  private navbarComponent$: Subject<void> = new Subject<void>();

  inProduction: boolean;
  isNavbarCollapsed: boolean;
  languages: any[];
  accountInfo: any;
  modalRef: NgbModalRef;
  version: string;
  userName: any;
  isSystemMaintenanceOn;
  currentMessage = new BehaviorSubject('123');
  isPasswordExpired: boolean;

  constructor(
    private el: ElementRef,
    private loginService: LoginService,
    private languageService: JhiLanguageService,
    private languageHelper: JhiLanguageHelper,
    private sessionStorage: SessionStorageService,
    private accountService: AccountService,
    private prescriptionService: PrescriptionRxService,
    private profileService: ProfileService,
    private idleService: IdleService,
    public router: Router,
    public _location: Location,
    private systemConfigService: SystemConfigRxService,
    private slideScreenService: AccountService
  ) {
    this.version = VERSION ? (VERSION.toLowerCase().startsWith('v') ? VERSION : 'v' + VERSION) : '';
    this.isNavbarCollapsed = true;
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (!this.el.nativeElement.contains(event.target)) {
      this.isNavbarCollapsed = true;
      this.slideScreenService.slideScreen.next(!this.isNavbarCollapsed);
    }
  }

  ngOnInit() {
    this.accountService.maintenanceScreen.pipe(takeUntil(this.navbarComponent$)).subscribe(data => {
      this.isSystemMaintenanceOn = data;
    });
    this.accountService.userNameBySubject.pipe(takeUntil(this.navbarComponent$)).subscribe(userNameFromSubject => {
      if (userNameFromSubject === '') {
        this.userName = ' ';
      } else {
        this.userName = userNameFromSubject;
      }
    });
    this.languageHelper.getAll().then(languages => {
      this.languages = languages;
    });

    this.accountService.identity().then(account => {
      if (account) {
        this.userName = account.username;
      }
    });

    this.loginService
      .getEmitter()
      .pipe(takeUntil(this.navbarComponent$))
      .subscribe(customObject => {
        this.sessionStorage.store('displayName', customObject);
        //  this.userName = customObject;
      });

    this.accountService
      .getEmitter()
      .pipe(takeUntil(this.navbarComponent$))
      .subscribe(customObject => {
        this.sessionStorage.store('displayName', customObject);
        //  this.userName = customObject;
      });
  }

  checkIfPasswordExpired() {
    this.isPasswordExpired = this.sessionStorage.retrieve('isPasswordExpired');
    if (this.isPasswordExpired == true) {
      return true;
    } else {
      return false;
    }
  }

  toggleNavbar() {
    this.slideScreenService.slideScreen.next(this.isNavbarCollapsed);

    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }

  changeLanguage(languageKey: string) {
    this.sessionStorage.store('locale', languageKey);
    this.languageService.changeLanguage(languageKey);
  }

  collapseNavbar() {
    this.isNavbarCollapsed = true;
    this.slideScreenService.slideScreen.next(this.isNavbarCollapsed);
  }

  isAuthenticated() {
    return this.accountService.isAuthenticated();
  }

  login() {
    this.isNavbarCollapsed = true;
    this.router.navigate(['/account/login']);
  }

  logout() {
    this.userName = '';
    this.accountService.userNameBySubject.next('');
    this.collapseNavbar();
    this.prescriptionService.resetSelectedPatient();
    sessionStorage.clear();
    this.idleService.stopWatching();
    this.loginService.logout();
    this.router.navigate(['/account/login']);
  }

  getImageUrl() {
    return this.isAuthenticated() ? this.accountService.getImageUrl() : null;
  }

  showMailDeliveryLink() {
    const rgMailDelURL = this.systemConfigService.getConfigValueFromLocalStorage('RgMailDel');
    if (rgMailDelURL) {
      window.open(rgMailDelURL);
    }
  }

  public ngOnDestroy(): void {
    this.navbarComponent$.next();
    this.navbarComponent$.unsubscribe();
  }
}
