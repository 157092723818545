import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { map } from 'rxjs/operators';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

import { SERVER_API_URL } from 'app/app.constants';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class AuthServerProvider {
  constructor(
    private http: HttpClient,
    private $localStorage: LocalStorageService,
    private $sessionStorage: SessionStorageService,
    private toastr: ToastrService
  ) {}

  getToken() {
    return this.$localStorage.retrieve('authenticationToken') || this.$sessionStorage.retrieve('authenticationToken');
  }

  login(credentials): Observable<any> {
    const data = {
      username: credentials.username,
      password: credentials.password,
      rememberMe: credentials.rememberMe
    };

    function authenticateSuccess(resp) {
      const oauthToken = resp.body.tokenDetails.access_token;
      this.storeAuthenticationToken(oauthToken, credentials.rememberMe);
      if (resp.body.notification_feeds) {
        this.showFeedNotification(resp.body.notification_feeds);
      }
      return oauthToken;
    }

    return this.http.post(SERVER_API_URL + 'api/authenticate', data, { observe: 'response' }).pipe(map(authenticateSuccess.bind(this)));
  }

  loginWithToken(jwt, rememberMe) {
    if (jwt) {
      this.storeAuthenticationToken(jwt, rememberMe);
      return Promise.resolve(jwt);
    } else {
      return Promise.reject('auth-jwt-service Promise reject'); // Put appropriate error message here
    }
  }

  storeAuthenticationToken(jwt, rememberMe) {
    if (rememberMe) {
      this.$localStorage.store('authenticationToken', jwt);
    } else {
      this.$sessionStorage.store('authenticationToken', jwt);
    }
  }

  logout(): Observable<any> {
    return new Observable(observer => {
      this.$localStorage.clear('authenticationToken');
      this.$sessionStorage.clear('authenticationToken');
      observer.complete();
    });
  }

  showFeedNotification(feeds) {
    try {
      feeds.forEach(element => {
        const toast = this.toastr.success(element.message.replace('</br>', ' \n '));
        toast.onTap.subscribe(() => {
          this.closeMessage(element.id, element.patientId);
        });
      });
    } catch (error) {
      console.warn('error on loading feeds');
    }
  }

  closeMessage(messageId, patientId) {
    const payload = {
      id: messageId,
      notificationRead: true
    };
    this.http.put(SERVER_API_URL + 'api/adminqueue/notificationread', payload).subscribe(res => {
      console.warn(res);
    });
  }

  refreshAccessToken() {
    return this.http.post(SERVER_API_URL + 'api/refreshtoken', null, { responseType: 'text' });
  }
}
