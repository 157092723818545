import 'core-js/es/reflect';
import 'core-js/stable/reflect';
import 'core-js/features/reflect';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ProdConfig } from './blocks/config/prod.config';
import { BjcrxAppModule } from './app.module';

ProdConfig();

if (module['hot']) {
  module['hot'].accept();
}

platformBrowserDynamic()
  .bootstrapModule(BjcrxAppModule, { preserveWhitespaces: true })
  .then(success => console.log('Application started'))
  .catch(err => console.error(err));
