import { NgModule } from '@angular/core';
import { BjcrxSharedLibsModule } from './shared-libs.module';
import { FindLanguageFromKeyPipe } from './language/find-language-from-key.pipe';
import { JhiAlertComponent } from './alert/alert.component';
import { JhiAlertErrorComponent } from './alert/alert-error.component';
import { JhiLoginModalComponent } from './login/login.component';
import { HasAnyAuthorityDirective } from './auth/has-any-authority.directive';
import { PhoneMaskDirective } from 'app/core/directive/phone-mask.directive';
import { FormDirective } from 'app/core/directive/form.directive';
import { DigitOnlyDirective } from 'app/core/directive/digit-only.directive';
import { NumberMaxlengthDirective } from 'app/core/directive/number-maxlength.directive';
import { NotificationConsentComponent } from 'app/account/notification-consent/notification-consent.component';
import { PreventDoubleClickDirective } from 'app/core/directive/prevent-double-click.directive';
import { DebounceClickDirective } from 'app/core/directive/debounce-click.directive';
import { TrimInputDirective } from 'app/core/directive/trim-input.directive';

@NgModule({
  imports: [BjcrxSharedLibsModule],
  declarations: [
    FindLanguageFromKeyPipe,
    JhiAlertComponent,
    JhiAlertErrorComponent,
    JhiLoginModalComponent,
    HasAnyAuthorityDirective,
    PhoneMaskDirective,
    FormDirective,
    DigitOnlyDirective,
    NumberMaxlengthDirective,
    NotificationConsentComponent,
    PreventDoubleClickDirective,
    DebounceClickDirective,
    TrimInputDirective
  ],
  entryComponents: [JhiLoginModalComponent],
  exports: [
    BjcrxSharedLibsModule,
    FindLanguageFromKeyPipe,
    JhiAlertComponent,
    JhiAlertErrorComponent,
    JhiLoginModalComponent,
    HasAnyAuthorityDirective,
    PhoneMaskDirective,
    FormDirective,
    DigitOnlyDirective,
    NumberMaxlengthDirective,
    NotificationConsentComponent,
    PreventDoubleClickDirective,
    DebounceClickDirective,
    TrimInputDirective
  ]
})
export class BjcrxSharedModule {}
