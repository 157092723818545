import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, take, switchMap, filter } from 'rxjs/operators';
import { ConfirmationDialogService } from '../../core/confirmation-dialog/confirmation-dialog.service';
import { LoginService } from '../../account/login/login.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { AuthServerProvider } from '../../core/auth/auth-jwt.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AuthExpiredInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  // available (e.g. refresh pending).
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private loginService: LoginService,
    private auth: AuthServerProvider,
    private confirmationDialogService: ConfirmationDialogService,
    private localStorage: LocalStorageService,
    private sessionStorage: SessionStorageService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {},
        (error: any) => {
          if (error instanceof HttpErrorResponse) {
            // We don't want to refresh token for some requests like login or refresh token itself
            // So we verify url and we throw an error if it's the case
            if (error.url.includes('authenticate') || error.url.includes('validateId')) {
              // We do another check to see if refresh token failed
              // In this case we want to logout user and to redirect it to login page

              if (error.url.includes('refreshtoken')) {
                this.showError();
              }

              return throwError(error);
            }

            // If error status is different than 401 we want to skip refresh token
            // So we check that and throw the error if it's the case
            if (error.status !== 401) {
              return throwError(error);
            }

            if (this.refreshTokenInProgress) {
              // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
              // – which means the new token is ready and we can retry the request again
              return this.refreshTokenSubject.pipe(
                filter(result => result !== null),
                take(1),
                switchMap(() => next.handle(this.addAuthenticationToken(request)))
              );
            } else {
              this.refreshTokenInProgress = true;

              // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
              this.refreshTokenSubject.next(null);

              // Call auth.refreshAccessToken(this is an Observable that will be returned)
              return this.auth.refreshAccessToken().subscribe(
                (token: any) => {
                  // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                  // for the next time the token needs to be refreshed
                  this.refreshTokenInProgress = false;
                  this.auth.storeAuthenticationToken(token, false);

                  return next.handle(this.addAuthenticationToken(request));
                },
                (err: any) => {
                  this.refreshTokenInProgress = false;

                  this.showError();
                  return throwError(error);
                }
              );
            }
          }
        }
      ).bind(this)
    );
  }

  addAuthenticationToken(request) {
    // Get access token from Local Storage
    const accessToken = this.localStorage.retrieve('authenticationToken') || this.sessionStorage.retrieve('authenticationToken');

    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!accessToken) {
      return request;
    }

    // We clone the request, because the original request is immutable
    return request.clone({
      setHeaders: {
        Authorization: accessToken,
        headers: request.headers.set('Cache-Control', 'no-cache').set('Pragma', 'no-cache')
      }
    });
  }

  showError() {
    this.confirmationDialogService
      .confirm('Session Expired', 'Your session has expired. Please login again to continue.', 'DELETE', 'CANCEL', 'lg', true, true)
      .then(confirmed => {
        this.loginService.logoutRedirect();
      });
  }
}
