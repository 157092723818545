import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { UserService } from 'app/core/user/user.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  constructor(private msgService: UserService, private angularFireMessaging: AngularFireMessaging, private toastr: ToastrService) {
    this.angularFireMessaging.messaging.subscribe(_messaging => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    });
  }

  subscribeToPushNotification(userId, token) {
    this.msgService.subscribeMsgService(userId, token).subscribe(
      resp => {
        if (resp) {
          console.warn(resp);
        }
        return resp;
      },
      error => {
        if (error) {
          if (error.error.text === 'Subscribed successfully to BJCRx push notifications') {
            return error.error.text;
          }
        }
      }
    );
  }
  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      token => {
        console.warn(token);
        const getNotify = this.subscribeToPushNotification(userId, token);
        return getNotify;
      },
      err => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(payload => {
      console.warn('new message received. ', payload);
      this.toastr.show(payload['notification']['body']);
      this.currentMessage.next(payload);
    });
  }
}
