import { Injectable, Output } from '@angular/core';
import { AccountService } from 'app/core/auth/account.service';
import { AuthServerProvider } from 'app/core/auth/auth-jwt.service';
import { Router } from '@angular/router';
import { EventEmitter } from '@angular/core';
import { MessagingService } from 'app/shared/messaging.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class LoginService {
  @Output() userName: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private accountService: AccountService,
    private authServerProvider: AuthServerProvider,
    private router: Router,
    private messagingService: MessagingService,
    private toastr: ToastrService
  ) {}

  login(credentials, callback?) {
    const cb = callback || function() {};

    return new Promise((resolve, reject) => {
      this.authServerProvider.login(credentials).subscribe(
        data => {
          resolve(data);
          return cb();
        },
        err => {
          reject(err);
          return cb(err);
        }
      );
    });
  }

  loginWithToken(jwt, rememberMe) {
    return this.authServerProvider.loginWithToken(jwt, rememberMe);
  }

  logout(clearToast = true) {
    sessionStorage.clear();
    this.authServerProvider.logout().subscribe(null, null, () => this.accountService.authenticate(null));
    this.userName.emit('');
    if (clearToast) {
      this.toastr.clear();
    }
  }

  logoutRedirect() {
    this.accountService.userNameBySubject.next('');
    sessionStorage.clear();
    this.authServerProvider.logout().subscribe(null, null, () => this.accountService.authenticate(null));
    this.userName.emit('');
    this.router.navigate(['/account/login']);
    this.toastr.clear();
  }

  getEmitter() {
    return this.userName;
  }
}
