import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AuthGuardService as AuthGuard } from '../core/auth/auth-guard.service';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'profile-rx',
        loadChildren: () => import('./profile-rx/profile-rx.module').then(m => m.BjcrxProfileRxModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'card-info-rx',
        loadChildren: () => import('./card-info-rx/card-info-rx.module').then(m => m.BjcrxCardInfoRxModule)
      },
      {
        path: 'prescription-tx-req-rx',
        loadChildren: () => import('./prescription-tx-req-rx/prescription-tx-req-rx.module').then(m => m.BjcrxPrescriptionTxReqRxModule)
      },
      {
        path: 'prescription-rx',
        loadChildren: () => import('./prescription-rx/prescription-rx.module').then(m => m.BjcrxPrescriptionRxModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'coupon-rx',
        loadChildren: () => import('./coupon-rx/coupon-rx.module').then(m => m.BjcrxCouponRxModule)
      },
      {
        path: 'pharmacy-locations-rx',
        loadChildren: () => import('./pharmacy-locations-rx/pharmacy-locations-rx.module').then(m => m.BjcrxPharmacyLocationsRxModule)
      },
      {
        path: 'customer-statement-rx',
        loadChildren: () => import('./customer-statement-rx/customer-statement-rx.module').then(m => m.BjcrxCustomerStatementRxModule)
      },
      {
        path: 'notification-message-rx',
        loadChildren: () => import('./notification-message-rx/notification-message-rx.module').then(m => m.BjcrxNotificationMessageRxModule)
      },
      {
        path: 'information',
        loadChildren: () => import('./information/information.module').then(m => m.BjcrxInfromationModule)
      },
      {
        path: 'maintenance',
        loadChildren: () => import('./system-maintenance/system-maintenance.module').then(m => m.BjcrxSystemMaintenanceModule)
      },
      {
        path: 'contact-us',
        loadChildren: () => import('./contact-us/contact-us.module').then(m => m.BjcrxContactUsModule)
      }
      /* jhipster-needle-add-entity-route - JHipster will add entity modules routes here */
    ]),
    ReactiveFormsModule
  ]
})
export class BjcrxEntityModule {}
