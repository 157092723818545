import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AccountService } from 'app/core/auth/account.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  roleName: any;
  maintenanceMsg: any;
  systemMsg: any;
  messages;
  constructor(public router: Router, private accountService: AccountService) {
    this.accountService.maintenanceScreen.subscribe(data => {
      this.messages = data;
    });
  }

  canActivate(): any {
    if (this.messages) {
      this.router.navigate(['/maintenance']);
      return false;
    }
    return true;
  }
}
